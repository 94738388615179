import { createSlice } from '@reduxjs/toolkit'
import { _setToken, getToken,_setUserInfo } from '@/utils/token.js'
import {
  loginAPI,
  signUpAPI,
  getClientInvestOverview,
  getClientBills,
  getClientProfile,
  addApyKey,
  verifyCodeAPI,
  getClientProfileV2,
  getClientProfitSummaryV2,
  getClientStrategyHistoryProfitV2,
  getClientStrategyHistoryApyV2
} from '@/apis/private.js'

const privateStore = createSlice({
  name: 'private',
  initialState: {
    token: getToken() ? getToken() : '', // 初始化 token
    clientInvestOverview: [], // 客户投资概览
    strategyHistoryAPY: {}, // 策略历史 APY
    strategyHistoryProfit: {}, // 策略历史利润
    apyFromChartDuration: 0,
    payment: [], // 支付信息
    profile: {}, // 客户档案
    clientProfileV2: [],
    clientProfitSummaryV2: [],
    clientApySummaryV2: [],
    clientStrategyHistoryProfitV2: [],
    clientStrategyHistoryApyV2: []
  },
  reducers: {
    // 设置 token
    setToken(state, action) {
      state.token = action.payload
      _setToken(action.payload)
    },
    setUserInfo(state,action) {
      _setUserInfo(action.payload)
    },

    // 设置客户投资概览
    setClientInvestOverview(state, action) {
      state.clientInvestOverview = action.payload
    },
    // 设置支付信息
    setPayment(state, action) {
      state.payment = action.payload
    },
    // 设置客户档案
    setProfile(state, action) {
      state.profile = action.payload
    },
    setClientProfileV2(state, action) {
      state.clientProfileV2 = action.payload
    },
    setClientProfitSummaryV2(state, action) {
      state.clientProfitSummaryV2 = action.payload
    },

    setClietnStrategyHistoryProfitV2(state, action) {
      state.clientStrategyHistoryProfitV2 = action.payload
    },
    setClientStrategyHistoryApyV2(state, action) {
      state.clientStrategyHistoryApyV2 = action.payload
    }
  }
})

const {
  setToken,
  setClientInvestOverview,
  setPayment,
  setProfile,
  setClientProfileV2,
  setClientProfitSummaryV2,
  setClietnStrategyHistoryProfitV2,
  setClientStrategyHistoryApyV2,
  setUserInfo
} = privateStore.actions

// 获取客户账单信息的异步请求
const fetchGetClientBills = () => {
  return async (dispatch) => {
    try {
      const res = await getClientBills()
      const formattedData = Object.keys(res).map((key) => ({
        ...res[key],
        quarter: key
      }))
      dispatch(setPayment(formattedData))
    } catch (error) {
      console.error('Failed to fetch client bills:', error)
    }
  }
}

// 登录异步请求
const fetchLogin = (loginFormData) => {
  return async (dispatch) => {
    try {
      const res = await loginAPI(loginFormData)
      if (res.message === 'Login successful') {
        dispatch(setToken(res?.data?.token))

        const userInfo = {
          username : res.data.username,
          first_name : res.data.first_name,
          last_name : res.data.last_name
        }
        dispatch(setUserInfo(userInfo))
        return { success: true, message: res.message }
      } else if (res?.message === 'Invalid username or password') {
        return { success: false, message: 'Invalid username or password' }
      } else {
        return {
          success: false,
          message: res?.message || 'somthing is wrong. Please try again later!'
        }
      }
    } catch (error) {
      return {
        success: false,
        message: 'somthing is wrong. Please try again later!'
      }
    }
  }
}

// 注册异步请求
const fetchSignUp = (signupFormData) => {
  return async () => {
    try {
      const res = await signUpAPI(signupFormData)
      if (res.message === 'success' && res.success === true) {
        return {
          success: true,
          message:
            'The verification code was sent successfully. Please verify the mailbox.'
        }
      } else {
        return { success: false, message: res?.message }
      }
    } catch (error) {
      return {
        success: false,
        message: 'somthing is wrong. Please try again later!'
      }
    }
  }
}




// 获取客户投资概览
const fetchGetClientInvestOverview = () => {
  return async (dispatch) => {
    try {
      const res = await getClientInvestOverview()
      if(res?.success === true && res?.message === 'ok'){
        dispatch(setClientInvestOverview(res.data))
      }
     
    } catch (error) {
      console.error('Failed to fetch client investment overview:', error)
    }
  }
}



// 获取客户档案
const fetchGetClientProfile = () => {
  return async (dispatch) => {
    try {
      const res = await getClientProfile()
      dispatch(setProfile(res))
    } catch (error) {
      console.error('Failed to fetch client profile:', error)
    }
  }
}

// 添加API密钥
const fetchAddApyKey = (key_name, key_value) => {
  return async () => {
    try {
      const res = await addApyKey(key_name, key_value)
      return res
    } catch (error) {
      console.error('Failed to add API key:', error)
    }
  }
}

// 校验验证码
const fetchPostVerifyCode = (formData) => {
  return async () => {
    try {
      const res = await verifyCodeAPI(formData)
      if (res?.message === 'Verification code valid') {
        return { success: true, message: 'Registration succeeded !' }
      } else if (res?.message === 'Invalid verification code') {
        return { success: false, message: 'Invalid verification code' }
      } else if (res?.message === 'No code requested for this user') {
        return { success: false, message: 'Verifacation code expired' }
      } else {
        return { success: false, message: res?.message }
      }
    } catch (error) {
      return {
        success: false,
        message: 'Something is wrong ,please try again!'
      }
    }
  }
}

const fetchClientProfileV2API = () => {
  return async (dispatch) => {
    try {
      const res = await getClientProfileV2()
      if (res?.success === true && res?.data) {
        dispatch(setClientProfileV2(res.data))
      }
    } catch (error) {
      return {
        success: false,
        message: 'Something is wrong ,please try again!'
      }
    }
  }
}

const fetchClientProfitSummaryV2API = (k) => {
  return async (dispatch) => {
    try {
      const res = await getClientProfitSummaryV2(k)
      if (res?.success === true && res?.data) {
        dispatch(setClientProfitSummaryV2(res.data))
      }
    } catch (error) {
      return {
        success: false,
        message: 'Something is wrong ,please try again!'
      }
    }
  }
}



const fetchClientStrategyHistoryProfitV2API = (timeWindow, product_name,k) => {
  return async (dispatch) => {
    try {
      const res = await getClientStrategyHistoryProfitV2(
        timeWindow,
        product_name,
        k
      )
      if (res.success === true && res.data) {
        dispatch(setClietnStrategyHistoryProfitV2(res?.data?.data))
      }

    } catch (error) {
      return {
        success: false,
        message: 'Something is wrong ,please try again!'
      }
    }
  }
}

const fetchClientStrategyHistoryApyV2API = (timeWindow, product_name) => {
  return async (dispatch) => {
    try {
      const res = await getClientStrategyHistoryApyV2(timeWindow, product_name)
      if (res.success === true && res.data) {
        dispatch(setClientStrategyHistoryApyV2(res?.data?.data))
      }

    } catch (error) {
      return {
        success: false,
        message: 'Something is wrong ,please try again!'
      }
    }
  }
}

const privateReducer = privateStore.reducer

export {
  fetchLogin,
  fetchSignUp,
  fetchGetClientInvestOverview,
  fetchGetClientBills, // 导出获取客户账单信息的异步请求
  fetchGetClientProfile, // 导出获取客户档案的异步请求
  fetchAddApyKey, // 导出添加API密钥的异步请求
  fetchPostVerifyCode,
  fetchClientProfileV2API,
  fetchClientProfitSummaryV2API,
  fetchClientStrategyHistoryProfitV2API,
  fetchClientStrategyHistoryApyV2API
}

export default privateReducer
