import { useState, useEffect } from 'react';
import { getToken } from '@/utils/index.js';
import { useNavigate } from 'react-router-dom';
import arrow_down from '../assets/arrow_down.svg';
import AdminDropDownMenu from '@/componets/AdminDropDownMenu';
import Button from '@/componets/Button/Button.js';
import Loading from '@/componets/Loading/Loading.js';

// 定义 Admin 组件
const Admin = () => {
    const navigate = useNavigate();
    const token = getToken();

    // 左侧标签列表，用于导航不同的内容
    const leftTabList = [
        { key: 'product', label: 'Product' },
        { key: 'account', label: 'Account' },
        { key: 'asset', label: 'Asset' },
        { key: 'exposure', label: 'Exposure' },
        { key: 'position', label: 'Position' },
        { key: 'investor', label: 'Investor' },
        { key: 'records', label: 'Records' },
        { key: 'coin_price', label: 'Coin Price' },
        { key: 'history', label: 'History' }
    ];

    // 定义组件的状态
    const [selectedData, setSelectedData] = useState(null); // 存储从 API 获取的数据
    const [selectedTab, setSelectedTab] = useState('product'); // 当前选中的标签
    const [loading, setLoading] = useState(false); // 加载状态
    const [error, setError] = useState(null); // 错误信息
    const [keysList, setKeysList] = useState([]); // 用于筛选的键列表
    const [rotatedArrows, setRotatedArrows] = useState({}); // 控制箭头旋转状态
    const [openMenus, setOpenMenus] = useState({}); // 控制下拉菜单的打开状态
    const [selectedMenuItem, setSelectedMenuItem] = useState({}); // 记录每个列选中的筛选项
    const [filteredData, setFilteredData] = useState([]); // 存储筛选后的数据
    const [selectedFilters, setSelectedFilters] = useState({}); // 当前应用的筛选条件

    console.log(selectedFilters); // 输出当前的筛选条件，便于调试

    // 处理左侧标签点击事件，切换选中的标签
    const handleClick = (tab) => {
        setSelectedTab(tab);
        setSelectedFilters({})
    };

    // 格式化显示的值，根据字段名进行不同的格式化处理
    const formatValue = (field, value) => {
        // 如果值是字符串，直接返回
        if (typeof value === 'string') {
            return value;
        }

        // 如果字段是 'apy'，格式化为百分比，保留两位小数
        if (field === 'apy') {
            return `${parseFloat(value).toFixed(2)}%`;
        }

        // 定义需要进行财务格式化的字段列表
        const accountingFields = [
            'aum',
            'net_asset_value',
            'future_value_long',
            'future_value_short',
            'net_exposure',
            'margin',
            'asset',
            'investment',
            'profit'
        ];

        // 如果字段在财务字段列表中，且值大于1000，进行千分位格式化
        if (accountingFields.includes(field)) {
            if(value > 1000){
                return parseFloat(value).toLocaleString('en-US');
            }
        }

        // 默认返回原值
        return value;
    };

    // 从 selectedData 中提取可用于筛选的键列表
    const extractKeys = (selectedData) => {
        if (selectedData && selectedData.filter_columns) {
            return Object.keys(selectedData.filter_columns);
        }
        return [];
    };


    // 当 selectedTab、token、navigate 发生变化时，触发数据获取
    useEffect(() => {
        if (!token) {
            // 如果没有 token，跳转到登录页面
            navigate('/login');
            return;
        }

        // 定义异步函数获取数据
        async function fetchData() {
            setLoading(true); // 开始加载
            setError(null); // 清除错误信息
            try {
                // 发送 POST 请求获取数据
                const response = await fetch(`https://api.stoneriver.io/admin/product?k=${selectedTab}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${token}`
                    }
                });

                if (!response.ok) {
                    // 如果响应不正常，抛出错误
                    throw new Error('Network response was not ok');
                }

                const res = await response.json(); // 解析响应数据
                if (res?.success === true && res?.message === "success") {
                    setSelectedData(res.data); // 设置获取的数据
                    setFilteredData(res.data.data); // 初始化筛选数据
                } else {
                    setSelectedData(null); // 如果获取失败，清空数据
                }

            } catch (error) {
                setError(error.message); // 设置错误信息
            } finally {
                setLoading(false); // 结束加载
            }
        }

        fetchData(); // 调用数据获取函数

    }, [selectedTab, token, navigate]);

    // 当 selectedData 变化时，提取新的键列表
    useEffect(() => {
        if (selectedData) {
            const newKeysList = extractKeys(selectedData);
            setKeysList(newKeysList);
        }
    }, [selectedData]);

    // 处理列头箭头点击事件
    const handleArrowClick = (name) => {
        // 只打开当前点击的菜单，关闭其他菜单
        setOpenMenus({
            [name]: !openMenus[name]  // 切换当前菜单的打开状态
        });

        // 切换箭头旋转状态
        setRotatedArrows((prev) => ({
            ...prev,
            [name]: !prev[name]
        }));

        // 如果当前列不可用于筛选（即不在 keysList 中），则进行排序
        if (!keysList.includes(name)) {
            const ascending = rotatedArrows[name];  // 根据箭头状态确定排序顺序
            const sortedData = [...filteredData].sort((a, b) => {
                if (ascending) {
                    return a[name] > b[name] ? 1 : -1;  // 升序排序
                } else {
                    return a[name] < b[name] ? 1 : -1;  // 降序排序
                }
            });
            setFilteredData(sortedData); // 更新筛选后的数据
        }
    };

    // 应用筛选条件，过滤数据
    const applyFilters = (filters) => {
        let filtered = selectedData.data;

        // 遍历每个筛选条件的键
        Object.keys(filters).forEach((key) => {
            if (filters[key]) {
                // 如果筛选条件存在，过滤数据
                filtered = filtered.filter((item) => item[key] === filters[key]);
            }
        });

        // 更新筛选后的数据
        setFilteredData(filtered);
    };

    // 处理下拉菜单项的点击事件
    const handleMenuItemClick = (name, selectedItem) => {
        // 关闭当前菜单
        setOpenMenus((prev) => ({
            ...prev,
            [name]: false
        }));

        // 设置箭头旋转状态为关闭
        setRotatedArrows((prev) => ({
            ...prev,
            [name]: true
        }));

        if (selectedItem === "All") {
            // 如果选择了 "All"，移除该筛选条件
            setSelectedFilters((prev) => {
                const { [name]: _, ...newFilters } = prev;
                applyFilters(newFilters); // 应用新的筛选条件
                return newFilters;
            });
        } else {
            // 添加或更新筛选条件
            setSelectedFilters((prev) => {
                const newFilters = { ...prev, [name]: selectedItem };
                applyFilters(newFilters); // 应用新的筛选条件
                return newFilters;
            });
        }

        // 更新选中的菜单项
        setSelectedMenuItem((prev) => ({
            ...prev,
            [name]: selectedItem
        }));
    };

    // 处理重置按钮点击事件，重置所有状态
    const handleReset = () => {
        setRotatedArrows({}); // 重置箭头旋转状态
        setOpenMenus({}); // 关闭所有菜单
        setSelectedFilters({}); // 清空筛选条件
        setSelectedMenuItem({}); // 清空选中的菜单项

        if (selectedData && selectedData.data) {
            setFilteredData(selectedData.data); // 重置筛选数据为原始数据
        } else {
            setFilteredData([]); // 如果没有数据，设置为空数组
        }
    };

    return (
        <div className="flex h-[85vh] max-w-[1440px] mx-auto mt-5  rounded-xl">
            {/* 左侧导航栏 */}
            <div className="w-[225px]] bg-white my-1 mx-3 rounded-xl px-3 py-5 gap-[10px] overflow-auto" style={{position:"relative"}}>
                <div className="p-5 gap-6 text-[20px] font-normal">
                    {leftTabList.map((item) => (
                        <div
                            key={item.key}
                            className={`mb-4 cursor-pointer ${selectedTab === item.key ? 'gradient-text text-semibold' : 'text-gray-500 text-normal'}`}
                            onClick={() => handleClick(item.key)}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
                {/* 重置按钮，位于左侧导航栏的底部 */}
                <div className="flex justify-center items-center absolute bottom-20 left-8">
                    <Button width='87px' height="36px" borderWidth={1} text={"Reset"} onClick={handleReset} />
                </div>
            </div>

            {/* 主内容区域 */}
            <div className="flex-1 flex-col bg-white my-1 mx-3 rounded-xl p-8 gap-1">
                {loading ? (
                    // 显示加载组件
                    <div>
                        <Loading />
                    </div>
                ) : error ? (
                    // 显示错误信息
                    <div className="text-red-500">Error: {error}</div>
                ) : selectedData && selectedData.columns_info ? (
                    <>
                        {/* 表格头部，显示列名和排序/筛选功能 */}
                        <div className="flex justify-between items-center rounded-full admin-gradient-border h-10 text-black font-semibold text-xs px-4 sticky top-0 z-10 bg-white">
                            {selectedData.columns_info.map((item, index) => (
                                <div className="flex-1 flex flex-row justify-center items-center cursor-pointer" key={index} onClick={() => handleArrowClick(item)} >
                                    <div className="text-center">{item}</div>
                                    <div>
                                        <button className="flex items-center relative">
                                            <img
                                                src={arrow_down}
                                                alt="arrow_down"
                                                className={`w-5 h-5 ml-2 transition-transform duration-300 ${rotatedArrows[item] ? 'rotate-180' : ''}`}
                                            />
                                        </button>
                                        {/* 如果该列可用于筛选，显示下拉菜单 */}
                                        {keysList.includes(item) && openMenus[item] && selectedData.filter_columns[item] && (
                                            <div className="absolute z-10">
                                                <AdminDropDownMenu
                                                    itemList={selectedData.filter_columns[item] || []}
                                                    onItemClick={(selectedItem) => handleMenuItemClick(item, selectedItem)}
                                                    selectedMenuItem={selectedMenuItem[item]}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* 表格内容区域 */}
                        <div className="flex-row justify-center items-center space-y-2 mt-2 overflow-auto h-[95%] ">
                            {filteredData.length > 0 ? (
                                // 显示筛选后的数据
                                filteredData.map((item, index) => (
                                    <div className="flex justify-between items-center h-10 px-4 border-b border-[#e8e8e8]" key={index}>
                                        {selectedData.columns_info.map((key, i) => (
                                            <div
                                                className={`flex-1 text-center ${typeof item[key] === 'number' && item[key] > 0 ? 'text-green-500' : item[key] < 0 ? 'text-red-500' : ''}`}
                                                key={i}
                                            >
                                                {formatValue(key, item[key])}
                                            </div>
                                        ))}
                                    </div>
                                ))
                            ) : (
                                // 如果没有数据，显示提示信息
                                <div className="flex justify-center items-center h-full">
                                    <div className="text-2xl font-semibold text-gray-600">No Data Available</div>
                                </div>
                            )}
                        </div>

                    </>
                ) : (
                    // 如果 selectedData 不存在，显示提示信息
                    <div className="flex justify-center items-center h-full">
                        <div className="text-2xl font-semibold text-gray-600">No Data Available</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Admin;
