// src/apis/private.js
import { request } from '@/utils/index.js'

// 登录 API
const loginAPI = (formData) => {
  return request({
    url: 'https://api.stoneriver.io/sign_in',
    method: 'POST',
    data: formData
  })
}

// 注册 API
const signUpAPI = (formData) => {
  return request({
    url: 'https://api.stoneriver.io/register',
    method: 'POST',
    data: formData
  })
}

// 验证码校验
const verifyCodeAPI = (formData) => {
  console.log(formData)
  return request({
    url: 'https://api.stoneriver.io/verify-code',
    method: 'POST',
    data: formData
  })
}


// getClientInvestOverview
const getClientInvestOverview = () => {
  return request({
    url: 'https://api.stoneriver.io/api/client_invest_overview',
    method: 'GET'
  })
}


// getClientBills
const getClientBills = () => {
  return request({
    url: 'https://api.stoneriver.io/api/client_bills',
    method: 'GET'
  })
}

// getClientProfile
const getClientProfile = () => {
  return request({
    url: 'https://api.stoneriver.io/api/client_profile',
    method: 'GET'
  })
}

// addApiKey
const addApyKey = (key_name, key_value) => {
  return request({
    url: 'https://api.stoneriver.io/add_api_key',
    method: 'POST',
    data: JSON.stringify({ key_name, key_value })
  })
}

const getClientProfileV2 = () => {
  return request({
    url: 'https://api.stoneriver.io/api/client_profile_v2',
    method: 'POST'
  })
}

const getClientProfitSummaryV2 = (k) => {
  return request({
    url: `https://api.stoneriver.io/api/client_profit_summary_v2?k=${k}`,
    method: 'POST'
  })
}



const getClientStrategyHistoryProfitV2 = (timeWindow, product_name,k) => {
  return request({
    url: `https://api.stoneriver.io/api/client_strategy_history_profit_v2?time_window=${timeWindow}&product_name=${product_name}&k=${k}`,

    method: 'GET'
  })
}

const getClientStrategyHistoryApyV2 = (timeWindow, product_name) => {
  return request({
    url: `https://api.stoneriver.io/api/client_strategy_history_apy_v2?time_window=${timeWindow}&product_name=${product_name}`,

    method: 'GET'
  })
}

export {
  loginAPI,
  signUpAPI,
  getClientInvestOverview,
  getClientBills,
  getClientProfile,
  addApyKey,
  verifyCodeAPI,
  getClientProfileV2,
  getClientProfitSummaryV2,
  getClientStrategyHistoryProfitV2,
  getClientStrategyHistoryApyV2
}
